import * as React from 'react';

/**
 * https://elfsight.com/instagram-feed-instashow/
 * https://apps.elfsight.com/panel/applications/instagram-testimonials/edit/c3001832-f3f6-4f5f-a6ee-c8ec172dca2c/
 * @param props
 */
const ElfsightInstagram: React.FunctionComponent = () => {
  return <div className="elfsight-app-c3001832-f3f6-4f5f-a6ee-c8ec172dca2c"> </div>;
};

export default ElfsightInstagram;
