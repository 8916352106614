import { ScriptLoader } from '@web/atomic/legacy/obj.script-loader';
import * as React from 'react';
import { ReactElement } from 'react';

interface IElfsightLoaderProps {
  children: ReactElement | ReactElement[];
}

/**
 * This component should wrap every elfsight component
 * https://help.elfsight.com/article/142-where-to-get-elfsight-widget-installation-code
 * @param props
 */
const ElfsightLoader: React.FunctionComponent<IElfsightLoaderProps> = (props) => {
  return <ScriptLoader src="https://apps.elfsight.com/p/platform.js">{() => props.children}</ScriptLoader>;
};

export default ElfsightLoader;
