import { BaseTheme } from '@web/atomic/obj.theme';
import { useTheme } from 'styled-components';
import * as React from 'react';
/**
 * https://elfsight.com/all-in-one-reviews-widget/
 * https://apps.elfsight.com/panel/applications/all-in-one-reviews/edit/f12b2959-d2ae-4923-b397-2d0eb4efdb0a/
 * @param props
 */
const ElfsightReviews: React.FunctionComponent = () => {
  const theme = useTheme() as BaseTheme;

  if (theme.name === 'dark') {
    return <div className="elfsight-app-c9139a58-62f1-4f81-b207-7730b1613e51"></div>;
  }

  return <div className="elfsight-app-f12b2959-d2ae-4923-b397-2d0eb4efdb0a"></div>;
};

export default ElfsightReviews;
