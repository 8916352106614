import { InView } from 'react-intersection-observer';
import { Col, FeaturedH2, Grid, H1, Headline, Row } from '@web/atomic';
import { getCanonicalUrl } from '@web/utils/url';
import { graphql } from 'gatsby';
import * as React from 'react';
import ElfsightInstagram from '../components/atm.elfsight/elfsight-instagram.component';
import ElfsightLoader from '../components/atm.elfsight/elfsight-loader.component';
import ElfsightReviews from '../components/atm.elfsight/elfsight-reviews.component';
import { SEO } from '../components/legacy/mol.seo/seo.component';
import IndexLayout from '../components/org.layout/layout.component';
import { ProfileSelectionSection } from '../modules/home/components/profile-selection-section.component';
import { TestimonialSelectionSection } from '../modules/home/components/testimonial-section.component';
import { PageProps } from '../utils/local-types';

const DepoimentosPage: React.FunctionComponent<PageProps> = (props) => {
  return (
    <IndexLayout {...props}>
      <SEO
        socialMedia={{
          canonicalUrl: getCanonicalUrl(props.data.site.siteMetadata.siteUrl, props.location.pathname),
          title: 'Depoimentos da Vigilantes do Sono',
          description: 'Confira depoimentos de pessoas que terminaram o programa e melhoraram o sono.',
        }}
      />

      <Grid>
        {/* TOP SECTION */}
        <Row mt center={'xs'}>
          <Col xs={12}>
            <H1>Depoimentos</H1>
            <Headline>Experiências e resultados de quem já esteve com a gente.</Headline>
          </Col>
        </Row>
      </Grid>

      <TestimonialSelectionSection hideBottomLink={true} showAllOnMobile={true} />

      <ElfsightLoader>
        <Grid>
          <InView triggerOnce={true}>
            {({ inView, ref }) => {
              return (
                <>
                  <FeaturedH2 ref={ref}>No Facebook e Google</FeaturedH2>
                  <Row center={'xs'}>
                    <Col xs={12}>
                      <ElfsightReviews />
                    </Col>
                  </Row>

                  <FeaturedH2>No Instagram</FeaturedH2>
                  <Row center={'xs'}>
                    <Col xs={12}>{inView && <ElfsightInstagram />}</Col>
                  </Row>
                </>
              );
            }}
          </InView>
        </Grid>
      </ElfsightLoader>

      <ProfileSelectionSection />
    </IndexLayout>
  );
};

export default DepoimentosPage;

export const query = graphql`
  query DepoimentosQuery {
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`;
